export const increment = (counterData) => {
    return {
      type: "INCREMENT",
      payload: counterData,
    };
  };
  export const decrement = (counterData) => {
    return {
      type: "DECREMENT",
      payload: counterData,
    };
  };
  export const reset = () => {
    return {
      type: "RESET",
    };
  };
  export const incrementByOne = () => {
    return {
      type: "INCREMENTBYONE",
    };
  };
  
  const initialState = { counterData: 0, previousCounterData: 0 };
  
  const counterReducer = (state = initialState, action) => {
    switch (action.type) {
      case "INCREMENT":
        return {
          ...state,
          previousCounterData: state.counterData,
          counterData: state.counterData + action.payload,
        };
      case "INCREMENTBYONE":
        return {
          ...state,
          previousCounterData: state.counterData,
          counterData: state.counterData + 1,
        };
      case "DECREMENT":
        return {
          ...state,
          previousCounterData: state.counterData,
          counterData: state.counterData - action.payload,
        };
      case "RESET":
        return {
          ...state,
          previousCounterData: state.counterData,
          counterData: 0,
        };
      default:
        return state;
    }
  };
  
  export default counterReducer;