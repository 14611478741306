import React from "react";
import { Provider } from "react-redux";

import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';

import { createStore as reduxCreateStore, compose } from "redux";
import rootReducer from "./reducers";

let composeEnhancers;

const createStore = () =>
  reduxCreateStore(
    rootReducer
    //,
    //(composeEnhancers =
    //  typeof window === "object"
    //    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
    //    : compose)
  );

var theOrigin = "http://localhost:8000";

if (typeof window !== `undefined`) {

  if (window.location.origin != "http://localhost:8000") {
    theOrigin = "https://www.boringmoney.co.uk/"
  }
}


export default ({ element }) => (

 

  
    <Provider store={createStore()}>{element}</Provider>
   

  
);



const onRedirectCallback = (appState) => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || '/', { replace: true });
};

