import React from "react"
import "@fontsource/space-grotesk"

class SessionCheck extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  handleCheckSession = () => {
    this.setState({ loading: false })
  }

  componentDidMount() {
   // silentAuth(this.handleCheckSession)
  }

  render() {
    return (
      this.state.loading === false && (
        <React.Fragment>{this.props.children}</React.Fragment>
      )
    )
  }
}

export const onClientEntry = () => {
  // Defer the prefetching of JSON until after the initial page load
  window.addEventListener('load', () => {
    const links = document.querySelectorAll('link[rel="prefetch"]');
    links.forEach(link => {
      const newLink = document.createElement('link');
      newLink.rel = 'prefetch';
      newLink.href = link.href;
      document.head.appendChild(newLink);
      link.remove();
    });
  });
};

export { default as wrapRootElement } from './src/redux/ReduxWrapper'
