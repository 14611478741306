import { combineReducers } from "redux";
import counterReducer from "./counter";
import trueReducer from "./isTrue";
import blurReducer from "./blur"
import comparatorReducer from "./comparators"
import menuItemHoverReducer from "./menuItemHover";
import userReducer from "./user";

const rootReducer = combineReducers({ trueReducer, counterReducer, blurReducer, comparatorReducer, menuItemHoverReducer, userReducer });

export default rootReducer
