const initialState = {
  isBlur: false,
};

const TOGGLE_BLUR = "TOGGLE_BLUR";
const TOGGLE_OFF = "TOGGLE_OFF";

export const toggleBlur = (isBlur) => ({
  type: TOGGLE_BLUR,
  isBlur,
});

export const toggleOff = (isBlur) => ({
  type: TOGGLE_OFF,
  isBlur,
});
export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_BLUR:
      return { ...state, isBlur: true };
    case TOGGLE_OFF:
      return { ...state, isBlur: false };
    default:
      return state;
  }
};
