export const makeTrue = () => {
    return {
      type: "MAKETRUE",
    };
  };
  export const makeFalse = () => {
    return {
      type: "MAKEFALSE",
    };
  };
  
  const trueReducer = (state = false, action) => {
    switch (action.type) {
      case "MAKETRUE":
        return true;
      case "MAKEFALSE":
        return false;
      default:
        return state;
    }
  };
  
  export default trueReducer;