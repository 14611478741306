const initialState = {
  isMenuItemHover: false,
};

const TOGGLE_MENU_ITEM_HOVER = "TOGGLE_MENU_ITEM_HOVER";
const TOGGLE_MENU_ITEM_HOVER_OFF = "TOGGLE_MENU_ITEM_HOVER_OFF";

export const toggleMenuItemHover = (isMenuItemHover) => ({
  type: TOGGLE_MENU_ITEM_HOVER,
  isMenuItemHover,
});

export const toggleMenuItemHoverOff = (isMenuItemHover) => ({
  type: TOGGLE_MENU_ITEM_HOVER_OFF,
  isMenuItemHover,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MENU_ITEM_HOVER:
      return { ...state, isMenuItemHover: true };
    case TOGGLE_MENU_ITEM_HOVER_OFF:
      return { ...state, isMenuItemHover: false };
    default:
      return state;
  }
};
