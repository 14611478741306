const initialState = {
  comparators: [
    // {
    //   // companyDisplayName: null,
    //   // companyLogo: null,
    //   // dashboardID: null,
    // },
    
  ],
};

const ADD_COMPARATOR = "ADD_COMPARATOR";
const REMOVE_COMPARATOR = "REMOVE_COMPARATOR";
const RESET_COMPARATORS = "RESET_COMPARATORS";

export const addComparator = (comparators) => ({
  type: ADD_COMPARATOR,
  payload: comparators,
})

export const removeComparator = (comparators) => ({
  type: REMOVE_COMPARATOR,
  payload: comparators,
})

export const resetComparators = (comparators) => ({
  type: RESET_COMPARATORS,
  payload: comparators,
})
export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_COMPARATOR:{
      const { companyDisplayName, companyLogo, dashboardId, linkHrefReview, type} = action.payload;
      //console.log(action.payload)
      return {
        ...state,
        comparators: [
          ...state.comparators,
          {
            companyDisplayName: companyDisplayName,
            companyLogo: companyLogo,
            dashboardId: dashboardId,
            linkHrefReview: linkHrefReview,
            type: type,
          },
        ],
      };
    }
    case REMOVE_COMPARATOR: {
      const { companyDisplayName, companyLogo, dashboardId } = action.payload;
      return {
        ...state,
        comparators: [
          ...state.comparators.filter(
            (comparator) => comparator.dashboardId !== dashboardId
          ),
        ],
      };
    }
    case RESET_COMPARATORS:{
      return initialState
    }
      default:
          return state;
  }
};
