const initialState = {
  isUserAuthenticated: false,
  token: null
};

const SET_IS_USER_AUTHENTICATED = "SET_IS_USER_AUTHENTICATED";
const SET_TOKEN = "SET_TOKEN";

export const setIsUserAuthenticated = (isUserAuthenticated) => ({
  type: SET_IS_USER_AUTHENTICATED,
  payload: {
    isUserAuthenticated: isUserAuthenticated
  },
});

export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: {
    token: token
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_USER_AUTHENTICATED:
      return { ...state, isUserAuthenticated: action.payload.isUserAuthenticated };
    case SET_TOKEN:
      return { ...state, token: action.payload.token };
    default:
      return state;
  }
};
